var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{staticClass:"navigation-drawer-wrapper",attrs:{"fixed":"","right":"","temporary":"","touchless":true},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-card',{staticClass:"navigation-drawer"},[_c('div',{staticClass:"navigation-drawer__top"},[_c('div',{staticClass:"navigation-drawer__title"},[_c('span',{staticClass:"navigation-drawer__title-text"},[_vm._v(_vm._s(_vm.appName))]),_c('v-btn',{staticClass:"navigation-drawer__close-button",attrs:{"small":"","depressed":""},on:{"click":_vm.handleCloseDrawerClick}},[_c('v-icon',{attrs:{"color":"#B4B4B4"}},[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"navigation-drawer__nav"},_vm._l((_vm.drawerMenuItems),function(item){return _c('v-btn',{key:item.name,class:[
            'navigation-drawer__nav-button',
            {
              'navigation-drawer__nav-button--active':
                _vm.$route.name === item.name,
            },
          ],attrs:{"depressed":"","plain":"","x-large":""},on:{"click":function($event){return _vm.navItemHandleClick(item.name)}}},[(item.customIcon)?_c('v-icon',{staticClass:"custom-nav-icon mr-3"},[_vm._v(" "+_vm._s(item.customIcon)+" ")]):_c('v-icon',{staticClass:"mr-3",attrs:{"color":"#BDBDBD"}},[_vm._v(_vm._s(`mdi-${item.icon}`))]),_c('span',[_vm._v(_vm._s(_vm.getTitle(item.title)))])],1)}),1),_c('div',{staticClass:"navigation-drawer__actions"},[_c('v-btn',{staticClass:"navigation-drawer__nav-button",attrs:{"depressed":"","plain":"","x-large":""},on:{"click":function($event){return _vm.handleLegalClicked({
              tr: 'contact_us',
            })}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"#B4B4B4"}},[_vm._v("mdi-message-text-outline")]),_c('span',[_vm._v(_vm._s(_vm.$t("map.contact_us")))])],1),_c('v-btn',{staticClass:"navigation-drawer__nav-button",attrs:{"depressed":"","plain":"","x-large":"","href":"https://t.me/turnintolocal","target":"_blank"}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"#B4B4B4"}},[_vm._v("$telegramMessage")]),_c('span',[_vm._v(_vm._s(_vm.$t("map.telegram_channel")))])],1)],1)]),_c('div',{staticClass:"navigation-drawer__bottom"},[(!this.$store.getters.isAuthenticated)?_c('v-btn',{staticClass:"navigation-drawer__profile-enter-button",attrs:{"depressed":"","color":"#fecc00","block":"","large":""},on:{"click":_vm.handleClickLogin}},[_vm._v(_vm._s(_vm.$t("profile.log_in")))]):_vm._e(),_c('div',{staticClass:"navigation-drawer__rules-lans-wrapper"},[_c('div',{staticClass:"navigation-drawer__rules"},_vm._l((_vm.LINKS),function(link){return _c('v-btn',{key:link.tr,staticClass:"navigation-drawer__rules-button",attrs:{"color":"#616161","small":"","depressed":"","plain":""},on:{"click":() => _vm.handleLegalClicked(link)}},[_vm._v(" "+_vm._s(_vm.$t(link.tr))+" ")])}),1),_c('span',{staticClass:"navigation-drawer__separator"}),_c('div',{staticClass:"navigation-drawer__langs"},[_c('span',{staticClass:"navigation-drawer__langs-text"},[_vm._v(" "+_vm._s(_vm.$t("profile.interface_language"))+" ")]),_c('LanguageMenu',{attrs:{"drop-top":""}})],1)])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }