import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store/index";

import i18n from "@/i18n";
import VueMeta from "vue-meta";
import vuetify from "../plugins/vuetify";

import { UTM_PARAMS, HTTP_REFERER } from "@/components/Common/constants";

const LandingView = () => import("@/views/LandingView.vue");
const MapView = () => import("@/views/MapView");
const CalcView = () => import("@/views/CalcView.vue");
const FAQView = () => import("@/views/FAQView.vue");
const ErrorPage = () => import("@/components/Common/ErrorPage.vue");
const LoginRegisterView = () => import("@/views/LoginRegisterView");
const ProfileView = () => import("@/views/ProfileView");
const ResetPasswordView = () => import("@/views/ResetPasswordView");
const ConfirmEmailView = () => import("@/views/ConfirmEmailView");
const EmailUnsubscribeView = () => import("@/views/EmailUnsubscribeView");
const EidsView = () => import("@/views/EidsView");
const DocumentView = () => import("@/views/DocumentView");
const CarCalcView = () => import("@/views/CarCalcView.vue");
const PartnerView = () => import("@/views/PartnerView/PartnerView.vue");
const InsuranceSearchView = () =>
  import("@/views/InsuranceView/InsuranceSearchView.vue");
const ConsultationsView = () =>
  import("@/views/ConsultationsView/ConsultationsView.vue");
const InsuranceCompanyView = () =>
  import("@/views/InsuranceView/InsuranceCompany/InsuranceCompanyView.vue");

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: "/:lang(en|ru|tr)?",
    component: {
      render: (h) => h("router-view"),
    },
    children: [
      { path: "", name: "home", component: LandingView },
      { path: "map", name: "map", component: MapView },
      {
        path: "auth",
        name: "auth",
        component: LoginRegisterView,
        meta: {
          hideForAuth: true,
        },
        beforeEnter: ifAuthenticated,
      },
      {
        path: "profile",
        name: "profile",
        component: ProfileView,
        meta: {
          requiresAuth: true,
        },
        beforeEnter: ifAuthenticated,
      },
      {
        path: "reset-password",
        name: "resetPassword",
        component: ResetPasswordView,
      },
      {
        path: "confirm-email",
        name: "confirmEmail",
        component: ConfirmEmailView,
      },
      {
        path: "email-unsubscribe",
        name: "confirmUnsubscribe",
        component: EmailUnsubscribeView,
      },
      {
        path: "eids",
        name: "eids",
        component: EidsView,
      },
      {
        path: "calc",
        name: "calc",
        component: CalcView,
        props: true,
      },
      {
        path: "calc/car",
        name: "car",
        component: CarCalcView,
      },
      {
        path: "partners/:id",
        name: "partners",
        component: PartnerView,
      },
      {
        path: "insurance-search",
        name: "insuranceSearch",
        component: InsuranceSearchView,
      },
      {
        path: "consultations",
        name: "consultations",
        component: ConsultationsView,
      },
      {
        path: "insurance-companies/:slug",
        name: "insuranceCompanies",
        component: InsuranceCompanyView,
      },
      { path: "faq", name: "faq", component: FAQView },
      { path: "404", name: "404", component: ErrorPage },
      {
        path: ":document(cookie|disclaimer|privacy|terms_of_service)",
        name: "document",
        component: DocumentView,
      },
      { path: ":catchAll(.*)", name: "error", redirect: "404" },
    ],
  },
];

const router = new VueRouter({
  scrollBehavior(to, from, SavedPosition) {
    if (to.hash) {
      const el = window.location.href.split("#")[1];
      if (el.length) {
        document.getElementById(el).scrollIntoView();
      }
    } else if (SavedPosition) {
      return SavedPosition;
    } else {
      if (document.getElementById("app")) {
        document.getElementById("app").scrollIntoView();
      }
    }
  },
  mode: "history",
  routes,
});

function ifAuthenticated(to, from, next) {
  if (to.meta && to.meta.hideForAuth && store.getters.isAuthenticated) {
    next({ name: "home" });
  } else if (
    to.meta &&
    to.meta.requiresAuth &&
    !store.getters.isAuthenticated
  ) {
    next({ name: "auth" });
  } else {
    next();
  }
}

const handleLanguageSettings = (to, next) => {
  const browserLang = (navigator.language || navigator.userLanguage).split(
    "-"
  )[0];
  const savedLang = localStorage.getItem("locale") || "en";
  let lang = to.params.lang;

  if (lang && !["en", "ru", "tr"].includes(lang)) {
    return next(savedLang);
  }

  if (i18n.locale !== lang) {
    if (!lang) {
      lang = browserLang || "en";
    }

    vuetify.framework.lang.current = lang;
    i18n.locale = lang;
    localStorage.setItem("locale", lang);
  }

  return next();
};

const captureUTMparams = (to) => {
  const urlParams = new URLSearchParams(to.fullPath.split("?")[1]);
  const utmParams = {};
  urlParams.forEach((value, key) => {
    if (key.startsWith("utm_")) {
      utmParams[key] = value;
    }
  });
  if (Object.keys(utmParams).length > 0) {
    localStorage.setItem(UTM_PARAMS, JSON.stringify(utmParams));
  }
};

const captureHTTPreferer = () => {
  if (document.referrer) {
    localStorage.setItem(HTTP_REFERER, document.referrer);
  }
};

router.beforeEach((to, from, next) => {
  captureUTMparams(to);
  captureHTTPreferer(to);
  return handleLanguageSettings(to, next);
});

export default router;
