<template>
  <v-navigation-drawer
    class="navigation-drawer-wrapper"
    v-model="drawer"
    fixed
    right
    temporary
    :touchless="true"
  >
    <v-card class="navigation-drawer">
      <div class="navigation-drawer__top">
        <div class="navigation-drawer__title">
          <span class="navigation-drawer__title-text">{{ appName }}</span>

          <v-btn
            small
            depressed
            class="navigation-drawer__close-button"
            @click="handleCloseDrawerClick"
          >
            <v-icon color="#B4B4B4">mdi-close</v-icon>
          </v-btn>
        </div>

        <div class="navigation-drawer__nav">
          <v-btn
            v-for="item in drawerMenuItems"
            :key="item.name"
            @click="navItemHandleClick(item.name)"
            depressed
            plain
            x-large
            :class="[
              'navigation-drawer__nav-button',
              {
                'navigation-drawer__nav-button--active':
                  $route.name === item.name,
              },
            ]"
          >
            <v-icon class="custom-nav-icon mr-3" v-if="item.customIcon">
              {{ item.customIcon }}
            </v-icon>
            <v-icon v-else color="#BDBDBD" class="mr-3">{{
              `mdi-${item.icon}`
            }}</v-icon>

            <span>{{ getTitle(item.title) }}</span>
          </v-btn>
        </div>

        <div class="navigation-drawer__actions">
          <v-btn
            depressed
            plain
            x-large
            class="navigation-drawer__nav-button"
            @click="
              handleLegalClicked({
                tr: 'contact_us',
              })
            "
          >
            <v-icon class="mr-3" color="#B4B4B4"
              >mdi-message-text-outline</v-icon
            >
            <span>{{ $t("map.contact_us") }}</span>
          </v-btn>

          <v-btn
            depressed
            plain
            x-large
            class="navigation-drawer__nav-button"
            href="https://t.me/turnintolocal"
            target="_blank"
          >
            <v-icon class="mr-3" color="#B4B4B4">$telegramMessage</v-icon>
            <span>{{ $t("map.telegram_channel") }}</span>
          </v-btn>
        </div>
      </div>

      <div class="navigation-drawer__bottom">
        <v-btn
          v-if="!this.$store.getters.isAuthenticated"
          depressed
          color="#fecc00"
          block
          large
          class="navigation-drawer__profile-enter-button"
          @click="handleClickLogin"
          >{{ $t("profile.log_in") }}</v-btn
        >

        <div class="navigation-drawer__rules-lans-wrapper">
          <div class="navigation-drawer__rules">
            <v-btn
              v-for="link in LINKS"
              :key="link.tr"
              class="navigation-drawer__rules-button"
              color="#616161"
              small
              depressed
              plain
              @click="() => handleLegalClicked(link)"
            >
              {{ $t(link.tr) }}
            </v-btn>
          </div>
          <span class="navigation-drawer__separator"></span>
          <div class="navigation-drawer__langs">
            <span class="navigation-drawer__langs-text">
              {{ $t("profile.interface_language") }}
            </span>
            <LanguageMenu drop-top />
          </div>
        </div>
      </div>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import router from "@/router";
import i18n from "@/i18n";
import { menuItems, drawerMenuItems } from "@/components/Common/constants";
import { LINKS } from "@/components/Common/constants";
import LanguageMenu from "@/components/Common/LanguageMenu.vue";

export default {
  name: "NavigationDrawer",
  components: { LanguageMenu },
  props: {
    isShowDrawer: Boolean,
  },

  data: () => ({
    LINKS,
    menuItems,
    drawerMenuItems,
    drawer: false,
  }),
  watch: {
    isShowDrawer() {
      this.drawer = this.isShowDrawer;
    },
    drawer() {
      if (!this.drawer) this.$emit("triggerShowDrawer");
    },
  },

  methods: {
    getTitle(title) {
      return this.$t(`app_bar.${String(title).toLowerCase()}`);
    },

    handleCloseDrawerClick() {
      this.drawer = false;
    },

    navItemHandleClick(item) {
      if (this.$route.name !== item) {
        router.push({ name: item, params: { lang: this.$route.params.lang } });
      }
      this.$emit("triggerShowDrawer");
    },

    handleLegalClicked(link) {
      this.handleCloseDrawerClick();
      this.$emit("handleLegalClicked", link);
    },

    handleClickLogin() {
      this.handleCloseDrawerClick();
      if (this.$route.name !== "auth") {
        this.$router.push({ name: "auth", params: { lang: i18n.locale } });
      }
    },
  },
  computed: {
    appliedLocale() {
      return i18n.locale;
    },
    appName() {
      return process.env.VUE_APP_NAME;
    },
  },
};
</script>

<style lang="scss">
.listItem {
  color: var(--color-text) !important;
  border-bottom: 1px solid var(--color-gray);
}
.navigation-drawer__logo {
  svg {
    width: auto;
    height: 16px;
  }
}
.navigation-drawer-wrapper {
  .v-btn__content {
    opacity: 1 !important;
  }
}
.v-icon.custom-nav-icon {
  svg {
    path {
      fill: #bdbdbd;
    }
  }
}
.navigation-drawer__nav-button--active {
  .v-icon.custom-nav-icon {
    svg {
      path {
        fill: #fecc00;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.navigation-drawer-wrapper {
  width: 400px !important;
  z-index: 99999;

  @media (max-width: 425px) {
    width: 300px !important;
  }

  .navigation-drawer {
    padding: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      &-text {
        color: #fecc00;
        font-weight: 700;
        font-family: Poppins, sans-serif;
      }
    }

    &__close-button {
      padding-inline: 0 !important;
      min-width: 30px !important;
    }

    &__nav {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &-button {
        justify-content: flex-start !important;
        text-transform: initial !important;
        color: #3d3d3d !important;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0;
        padding-inline: 8px !important;
        margin-inline: -8px !important;
        transition: background 0.25s ease;

        &--active {
          background-color: #f9b3343b;
          .v-icon {
            color: #fbad00 !important;
          }

          svg {
            path {
              fill: #bdbdbd;
            }
          }
        }

        &:hover {
          &:not(.navigation-drawer__nav-button--active) {
            background-color: #eaeaea;
          }

          .v-icon {
            color: #fbad00 !important;
          }
        }
      }
    }

    &__actions {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
    }

    &__profile-enter-button {
      margin-bottom: 48px;
      border-radius: 8px !important;
      color: #3d3d3d;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-transform: initial;
      letter-spacing: 0;
    }

    &__rules-lans-wrapper {
      background-color: #f5f5f5;
      border-radius: 8px;
      margin: -8px;
      padding: 16px;
    }

    &__rules {
      display: flex;
      flex-direction: column;

      &-button {
        justify-content: flex-start !important;
        text-transform: initial !important;
        padding-inline: 8px !important;
        margin-inline: -8px !important;
        letter-spacing: 0;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1.25px;

        &:hover {
          background-color: #eaeaea;
        }
      }
    }

    &__separator {
      border-top: 1px solid #d7d7d7;
      margin: 16px 8px;
      display: block;
    }

    &__langs {
      display: flex;
      flex-direction: column;
      margin-top: 8px;
      margin-right: -16px;
      margin-left: -16px;
      position: relative;

      &-text {
        color: #8c8c8c;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        padding-left: 16px;
        padding-right: 16px;
        text-transform: uppercase;
      }
    }
  }
}
</style>
