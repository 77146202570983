import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import TelegramIcon from "@/components/Common/CustomIcons/TelegramIcon";
import GoogleIcon from "@/components/Common/CustomIcons/GoogleIcon";
import YandexIcon from "@/components/Common/CustomIcons/YandexIcon";

import en from "vuetify/src/locale/en.ts";
import ru from "vuetify/src/locale/ru.ts";
import tr from "vuetify/src/locale/tr.ts";
import HomeIcon from "@/components/Common/CustomIcons/HomeIcon";
import PharmacyIcon from "@/components/Common/CustomIcons/PharmacyIcon";
import DutyPharmacyIcon from "@/components/Common/CustomIcons/DutyPharmacyIcon.vue";
import MarketsIcon from "@/components/Common/CustomIcons/MarketsIcon";
import MapIcon from "@/components/Common/CustomIcons/MapIcon";
import ShareIcon from "@/components/Common/CustomIcons/ShareIcon";
import BookmarkIcon from "@/components/Common/CustomIcons/BookmarkIcon";
import LinkIcon from "@/components/Common/CustomIcons/LinkIcon";
import AttentionIcon from "@/components/Common/CustomIcons/AttentionIcon";
import ArrowLeftIcon from "@/components/Common/CustomIcons/ArrowLeftIcon";
import ArrowRightIcon from "@/components/Common/CustomIcons/ArrowRightIcon";
import TelegramOutlineIcon from "@/components/Common/CustomIcons/TelegramOutlineIcon";
import ViberOutlineIcon from "@/components/Common/CustomIcons/ViberOutlineIcon";
import WhatsappOutlineIcon from "@/components/Common/CustomIcons/WhatsappOutlineIcon";
import FilterActiveIcon from "@/components/Common/CustomIcons/FilterActiveIcon";
import GocLogoIcon from "@/components/Common/CustomIcons/GocLogoIcon";
import ApartmentAreaIcon from "@/components/Common/CustomIcons/ApartmentAreaIcon";
import ApartmentLayoutIcon from "@/components/Common/CustomIcons/ApartmentLayoutIcon";
import CalendarIcon from "@/components/Common/CustomIcons/CalendarIcon";
import SortAscIcon from "@/components/Common/CustomIcons/SortAscIcon";
import SortDescIcon from "@/components/Common/CustomIcons/SortDescIcon";
import MarkerDistanceIcon from "@/components/Common/CustomIcons/MarkerDistanceIcon";
import PassportIcon from "@/components/Common/CustomIcons/PassportIcon.vue";
import MessageIcon from "@/components/Common/CustomIcons/MessageIcon";
import TelegramMessageIcon from "@/components/Common/CustomIcons/TelegramMessageIcon";
import TextLogoIcon from "@/components/Common/CustomIcons/TextLogoIcon";
import DownArrowIcon from "@/components/Common/CustomIcons/DownArrowIcon";
import MagnifyIcon from "@/components/Common/CustomIcons/MagnifyIcon.vue";
import HeartRateIcon from "@/components/Common/CustomIcons/HeartRateIcon.vue";
import HospitalIcon from "@/components/Common/CustomIcons/HospitalIcon.vue";
import BriefcaseIcon from "@/components/Common/CustomIcons/BriefcaseIcon.vue";
import BuildingIcon from "@/components/Common/CustomIcons/BuildingIcon.vue";
import IndividualIcon from "@/components/Common/CustomIcons/IndividualIcon.vue";
import BedIcon from "@/components/Common/CustomIcons/BedIcon.vue";
import FamilyCenterIcon from "@/components/Common/CustomIcons/FamilyCenterIcon.vue";
import MapPlaceIcon from "@/components/Common/CustomIcons/MapPlaceIcon.vue";
import DocumentCheckIcon from "@/components/Common/CustomIcons/DocumentCheckIcon.vue";
import GlobeIcon from "@/components/Common/CustomIcons/GlobeIcon.vue";
import UserIcon from "@/components/Common/CustomIcons/UserIcon.vue";
import AiGenerateIcon from "@/components/Common/CustomIcons/AiGenerateIcon.vue";
// Features
import FullHomeIcon from "@/components/Common/CustomIcons/Features/CommonState/FullHomeIcon.vue";
import HomeCheckedIcon from "@/components/Common/CustomIcons/Features/CommonState/HomeCheckedIcon.vue";
import HomeStateIcon from "@/components/Common/CustomIcons/Features/CommonState/HomeStateIcon.vue";
import RollerIcon from "@/components/Common/CustomIcons/Features/CommonState/RollerIcon.vue";
import MountainIcon from "@/components/Common/CustomIcons/Features/View/MountainIcon.vue";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      dark: false,
      light: {
        "primary-light": "#0062FF",
        primary: "#3d3d3d",
        secondary: "#fecc00",
        error: "#B00020",
        "light-gray-3": "#f5f5f5",
        "gray-2": "#8c8c8c",
      },
    },
  },
  lang: {
    locales: { en, ru, tr },
    current: "en",
  },
  icons: {
    values: {
      telegram: {
        component: TelegramIcon,
      },
      google: {
        component: GoogleIcon,
      },
      yandex: {
        component: YandexIcon,
      },
      home: {
        component: HomeIcon,
      },
      pharmacy: {
        component: PharmacyIcon,
      },
      dutyPharmacy: {
        component: DutyPharmacyIcon,
      },
      market: {
        component: MarketsIcon,
      },
      map: {
        component: MapIcon,
      },
      share: {
        component: ShareIcon,
      },
      bookmark: {
        component: BookmarkIcon,
      },
      link: {
        component: LinkIcon,
      },
      attention: {
        component: AttentionIcon,
      },
      arrowLeft: {
        component: ArrowLeftIcon,
      },
      arrowRight: {
        component: ArrowRightIcon,
      },
      whatsappOutline: {
        component: WhatsappOutlineIcon,
      },
      viberOutline: {
        component: ViberOutlineIcon,
      },
      telegramOutline: {
        component: TelegramOutlineIcon,
      },
      message: {
        component: MessageIcon,
      },
      telegramMessage: {
        component: TelegramMessageIcon,
      },
      filterActive: {
        component: FilterActiveIcon,
      },
      gocLogoIcon: {
        component: GocLogoIcon,
      },
      apartmentArea: {
        component: ApartmentAreaIcon,
      },
      apartmentLayout: {
        component: ApartmentLayoutIcon,
      },
      calendar: {
        component: CalendarIcon,
      },
      sortAsc: {
        component: SortAscIcon,
      },
      sortDesc: {
        component: SortDescIcon,
      },
      markerDistance: {
        component: MarkerDistanceIcon,
      },
      passport: {
        component: PassportIcon,
      },
      textLogo: {
        component: TextLogoIcon,
      },
      downArrow: {
        component: DownArrowIcon,
      },
      magnify: {
        component: MagnifyIcon,
      },
      heartRate: {
        component: HeartRateIcon,
      },
      hospital: {
        component: HospitalIcon,
      },
      briefcase: {
        component: BriefcaseIcon,
      },
      building: {
        component: BuildingIcon,
      },
      individual: {
        component: IndividualIcon,
      },
      // Features
      fullHome: {
        component: FullHomeIcon,
      },
      homeChecked: {
        component: HomeCheckedIcon,
      },
      homeState: {
        component: HomeStateIcon,
      },
      roller: {
        component: RollerIcon,
      },
      mountain: {
        component: MountainIcon,
      },
      bed: {
        component: BedIcon,
      },
      familyCenter: {
        component: FamilyCenterIcon,
      },
      mapPlace: {
        component: MapPlaceIcon,
      },
      documentCheck: {
        component: DocumentCheckIcon,
      },
      globe: {
        component: GlobeIcon,
      },
      user: {
        component: UserIcon,
      },
      aiGenerate: {
        component: AiGenerateIcon,
      },
    },
  },
});
